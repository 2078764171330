import React, { useState } from "react";
import {
  Box,
  TextField,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Avatar,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import styles from "./Projects.module.css";
import projectsData from "../utils/ProjectsData";

function Projects() {
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Filter projects based on search query
  const filteredProjects = projectsData.filter((project) =>
    project.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleProjectClick = (url) => {
    navigate(url);
  };

  return (
    <Box className={styles.projects_container}>
      <Typography variant="h3" className={styles.title}>
        My Projects
      </Typography>
      <TextField
        variant="outlined"
        label="Search Projects"
        value={searchQuery}
        onChange={handleSearchChange}
        fullWidth
        className={styles.searchField}
      />

      <List className={styles.project_list}>
        {filteredProjects.length > 0 ? (
          filteredProjects.map((project) => (
            <ListItem
              key={project.id}
              onClick={() => handleProjectClick(project.url)}
              className={styles.project_item}
            >
              <ListItemAvatar>
                <Avatar
                  alt={project.name}
                  src={project.thumbnail}
                  variant="square"
                  className={styles.thumbnail}
                />
              </ListItemAvatar>
              <ListItemText
                primary={<Typography variant="h5">{project.name}</Typography>}
                secondary={
                  <Typography variant="body1">{project.description}</Typography>
                }
                className={styles.project_text}
              />
            </ListItem>
          ))
        ) : (
          <ListItem>
            <ListItemText primary="No projects found" />
          </ListItem>
        )}
      </List>
    </Box>
  );
}

export default Projects;
