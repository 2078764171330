import { Box } from "@mui/material";
import React from "react";
import styles from "./Home.module.css";
import programmerImg from "../shared/images/undraw_programming.svg";
import HtmlImg from "../shared/images/html.jpg";
import MuiImg from "../shared/images/mui.png";
import CssImg from "../shared/images/css.png";
import JsImg from "../shared/images/js.png";
import TsImg from "../shared/images/ts.png";
import ReactImg from "../shared/images/react.png";
import ReduxImg from "../shared/images/redux.png";
import GitImg from "../shared/images/git.png";
import NodeImg from "../shared/images/node.png";
import MongodbImg from "../shared/images/mongodb.png";

function Home() {
  const skills = [
    { name: "HTML", img: HtmlImg },
    { name: "CSS", img: CssImg },
    { name: "JavaScript", img: JsImg },
    { name: "TypeScript", img: TsImg },
    { name: "React", img: ReactImg },
    { name: "Redux", img: ReduxImg },
    { name: "Material-UI", img: MuiImg },
    { name: "Git", img: GitImg },
    { name: "NodeJs", img: NodeImg },
    { name: "MongoDB", img: MongodbImg },
  ];

  return (
    <Box className={styles.home_container}>
      {/* Hero Section */}
      <Box className={styles.home_top_hero_section}>
        <Box className={styles.home_title}>
          <h2>Hello, I'm</h2>
          <h1 className={styles.name}>Sai Vaddemani</h1>
          <h3 className={styles.sub_title}>Transforming Ideas into Reality</h3>
          <p className={styles.description}>
            As a dedicated web developer, I specialize in crafting dynamic and
            user-centric web applications. Whether you need a sleek portfolio
            site or a robust business platform, I’m here to make your vision a
            reality.
          </p>
        </Box>
        <Box className={styles.home_hero}>
          <img src={programmerImg} alt="hero" className={styles.hero} />
        </Box>
      </Box>

      {/* Tech Stack Section */}
      <Box className={styles.home_services_section}>
        <h1 className={styles.skills_title}>Tech Stack & Expertise</h1>
        <h3 className={styles.sub_title}>
          I leverage cutting-edge tools and frameworks to deliver efficient,
          high-quality products.
        </h3>
        <Box className={styles.skills}>
          {skills.map((item) => (
            <Box key={item.name} className={styles.skill}>
              <img src={item.img} alt={item.name} />
              <h5>{item.name}</h5>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

export default Home;
