import React from "react";
import styles from "./Footer.module.css";
import { Box, Typography } from "@mui/material";

function Footer() {
  return (
    <Box className={styles.footer_container}>
      <Typography variant="body">
        For collaborations and queries, reach out to me at &nbsp;
        <a href="mailto:saiv5350@gmail.com">saiv5350@gmail.com</a>&nbsp; or any
        social media links above.
      </Typography>
    </Box>
  );
}

export default Footer;
