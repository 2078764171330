import React from "react";
import { Box, Typography, TextField, Button } from "@mui/material";
import { GitHub, LinkedIn, Email } from "@mui/icons-material";
import styles from "./Contact.module.css"; // Import the CSS file for styling

function Contact() {
  return (
    <Box className={styles.contact_container}>
      <Typography variant="h3" className={styles.title}>
        Get in Touch
      </Typography>
      <Typography variant="body1" className={styles.description}>
        I’d love to hear from you! Whether you have a question or just want to
        say hello, feel free to reach out.
      </Typography>

      {/* Contact Info Section */}
      <Box className={styles.contact_info}>
        <Box className={styles.info_item}>
          <Email className={styles.icon} />
          <Typography variant="body1">saiv5350@gmail.com</Typography>
        </Box>
        <Box className={styles.info_item}>
          <LinkedIn className={styles.icon} />
          <Typography variant="body1">
            <a
              href="https://www.linkedin.com/in/saivaddemani"
              target="_blank"
              rel="noopener noreferrer"
            >
              LinkedIn Profile
            </a>
          </Typography>
        </Box>
        <Box className={styles.info_item}>
          <GitHub className={styles.icon} />
          <Typography variant="body1">
            <a
              href="https://github.com/v-sai"
              target="_blank"
              rel="noopener noreferrer"
            >
              GitHub Profile
            </a>
          </Typography>
        </Box>
      </Box>

      {/* Contact Form */}
      <Box component="form" className={styles.contact_form}>
        <TextField
          label="Your Name"
          variant="outlined"
          fullWidth
          className={styles.input}
        />
        <TextField
          label="Your Email"
          variant="outlined"
          fullWidth
          className={styles.input}
        />
        <TextField
          label="Message"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          className={styles.input}
        />
        <Button
          variant="contained"
          color="primary"
          className={styles.submit_button}
        >
          Send Message
        </Button>
      </Box>
    </Box>
  );
}

export default Contact;
