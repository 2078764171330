import React from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, Chip } from "@mui/material";
import styles from "./ProjectDetails.module.css";
import projectsData from "../utils/ProjectsData";

function ProjectDetails() {
  const { id } = useParams();
  const project = projectsData.find((p) => p.id === parseInt(id));

  if (!project) {
    return <h2>Project not found</h2>;
  }

  return (
    <Box className={styles.project_details_container}>
      <Typography variant="h3" className={styles.project_title}>
        {project.name}
      </Typography>
      <Typography variant="body1" className={styles.project_description}>
        {project.description}
      </Typography>

      <Typography variant="h4" className={styles.section_title}>
        Technologies Used:
      </Typography>
      <Box className={styles.technologies_container}>
        {project.technologies.map((tech, index) => (
          <Chip key={index} label={tech} className={styles.technology_chip} />
        ))}
      </Box>

      <Typography variant="h4" className={styles.section_title}>
        Project Images
      </Typography>
      <Box className={styles.image_gallery}>
        {project.images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Project Image ${index + 1}`}
            className={styles.project_image}
          />
        ))}
      </Box>
    </Box>
  );
}

export default ProjectDetails;
