const projectsData = [
  {
    id: 1,
    name: "E-Commerce Platform",
    description:
      "A full-featured e-commerce platform inspired by Amazon, with user authorization for both buyers and sellers. Built with modern technologies such as React, Redux Toolkit, and Material-UI. It supports product listings, cart functionality, and secure payment processing.",
    url: "/projects/1",
    thumbnail: "https://picsum.photos/600/400",
    images: [
      "https://picsum.photos/600/400?random=1",
      "https://picsum.photos/600/400?random=2",
      "https://picsum.photos/600/400?random=3",
    ],
    technologies: ["React", "Redux Toolkit", "NodeJS", "MongoDB"],
  },
  {
    id: 2,
    name: "Social Media App",
    description:
      "A social media platform allowing users to create profiles, follow others, and engage in real-time conversations. Developed using React for the frontend, Node.js for the backend, and MongoDB for storing user data.",
    url: "/projects/2",
    thumbnail: "https://picsum.photos/600/350",
    images: [
      "https://picsum.photos/600/400?random=4",
      "https://picsum.photos/600/400?random=5",
      "https://picsum.photos/600/400?random=6",
    ],
    technologies: ["React", "NodeJS", "MongoDB"],
  },
  {
    id: 3,
    name: "Project Management Tool",
    description:
      "A tool designed for teams to collaborate, track tasks, and manage projects efficiently. It includes task assignment, project timelines, and team communication features.",
    url: "/projects/3",
    thumbnail: "https://picsum.photos/600/360",
    images: [
      "https://picsum.photos/600/400?random=7",
      "https://picsum.photos/600/400?random=8",
      "https://picsum.photos/600/400?random=9",
    ],
    technologies: ["React", "NodeJS", "Express", "PostgreSQL"],
  },
  {
    id: 4,
    name: "Personal Portfolio Website",
    description:
      "A responsive portfolio website showcasing my projects, skills, and experience. Built using React and Material-UI to create a clean, professional design.",
    url: "/projects/4",
    thumbnail: "https://picsum.photos/600/380",
    images: [
      "https://picsum.photos/600/400?random=10",
      "https://picsum.photos/600/400?random=11",
      "https://picsum.photos/600/400?random=12",
    ],
    technologies: ["React", "Material-UI"],
  },
  {
    id: 5,
    name: "Weather Forecast App",
    description:
      "A weather forecasting app that provides real-time weather updates using the OpenWeather API. Features include current conditions, 7-day forecast, and location-based weather data.",
    url: "/projects/5",
    thumbnail: "https://picsum.photos/600/340",
    images: [
      "https://picsum.photos/600/400?random=13",
      "https://picsum.photos/600/400?random=14",
      "https://picsum.photos/600/400?random=15",
    ],
    technologies: ["React", "OpenWeather API"],
  },
];

export default projectsData;
