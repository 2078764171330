import React, { useState } from "react";
import {
  Box,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MenuIcon from "@mui/icons-material/Menu"; // Hamburger menu icon
import styles from "./Header.module.css";

import logo from "../../../assets/logo.png"

function Header() {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const handleMenuClick = () => {
    setIsDrawerOpen(false); // Close the drawer when a menu item is clicked
  };

  const menuItems = (
    <List className={styles.drawerList}>
      <ListItem component={Link} to={"/"} onClick={handleMenuClick}>
        <ListItemText primary="Home" />
      </ListItem>
      <ListItem component={Link} to={"/projects"} onClick={handleMenuClick}>
        <ListItemText primary="Projects" />
      </ListItem>
      <ListItem component={Link} to={"/blog"} onClick={handleMenuClick}>
        <ListItemText primary="Blog" />
      </ListItem>
      <ListItem component={Link} to={"/contact"} onClick={handleMenuClick}>
        <ListItemText primary="Contact me" />
      </ListItem>

      <ListItem
        component={Link}
        to={"https://github.com/v-sai"}
        onClick={handleMenuClick}
      >
        <GitHubIcon fontSize="large" />
      </ListItem>
      <ListItem
        component={Link}
        to={"https://www.linkedin.com/in/saivaddemani"}
        onClick={handleMenuClick}
      >
        <LinkedInIcon fontSize="large" />
      </ListItem>
    </List>
  );

  return (
    <Box className={styles.header_container}>
      <Link to={"/"} className={styles.title}>
        <img src={logo} alt="logo" className={styles.logo}/>
      </Link>

      {/* Desktop Links */}
      <Box className={styles.header_nav_links}>
        <Link to={"/projects"}>Projects</Link>
        <Link to={"/blog"}>Blog</Link>
        <Link to={"/contact"}>Contact me</Link>
        <Link to={"https://github.com/v-sai"}>
          <GitHubIcon fontSize="large" />
        </Link>
        <Link to={"https://www.linkedin.com/in/saivaddemani"}>
          <LinkedInIcon fontSize="large" />
        </Link>
      </Box>


      {/* Hamburger Menu for Mobile */}
      <IconButton
        className={styles.menuButton}
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
      >
        <MenuIcon fontSize="large" />
      </IconButton>

      {/* Drawer for Mobile Menu */}
      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        {menuItems}
      </Drawer>
    </Box>
  );
}

export default Header;
