import {
  Box,
  InputAdornment,
  TextField,
  Typography,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import styles from "./Blog.module.scss";
import { Search, UnfoldMore } from "@mui/icons-material";
import BlogpostThumbnail from "./BlogpostThumbnail/BlogpostThumbnail";

export default function Blog() {
  const [filters] = useState([
    { id: 0, label: "Newest first" },
    { id: 1, label: "Oldest first" },
    { id: 2, label: "Most Viewed" },
    { id: 3, label: "Most Liked" },
  ]);

  const [anchorEl, setAnchorEl] = useState(null);

  const [selectedFilter, setSelectedFilter] = useState(filters[0]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleFilterSelect = (filter) => {
    setSelectedFilter(filter);
    handleClose();
  };

  const open = Boolean(anchorEl);

  return (
    <Box sx={{width:"100vw"}}>
      <Typography variant="h4" sx={{ textAlign: "center" }}>
        Blog is undergoing some changes, will be back soon
      </Typography>
    </Box>
  );

  return (
    <Box className={styles.container}>
      <Box className={styles.blog_left}>
        <Box className={styles.blog_header}>
          <Typography variant="h5" className={styles.title}>
            {selectedFilter.label}
          </Typography>
          <Box className={styles.header_filters}>
            <TextField
              id="outlined-size-small"
              size="small"
              placeholder="Search..."
              className={styles.search}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />
            <Box onClick={handleClick} className={styles.filter}>
              <Typography variant="body" className={styles.filter_text}>
                {selectedFilter.label}
              </Typography>
              <UnfoldMore fontSize="small" />
            </Box>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
              {filters.map((filter) => (
                <MenuItem
                  key={filter.id}
                  onClick={() => handleFilterSelect(filter)}
                  className={
                    filter.id === selectedFilter.id && styles.selectedMenuItem
                  }
                >
                  {filter.label}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Box>
        <Box>
          <BlogpostThumbnail />
          <BlogpostThumbnail />
          <BlogpostThumbnail />
          <BlogpostThumbnail />
          <BlogpostThumbnail />
          <BlogpostThumbnail />
          <BlogpostThumbnail />
        </Box>
      </Box>
      <Box className={styles.blog_right}>
        <Typography variant="h6">Most Viewed</Typography>
      </Box>
    </Box>
  );
}
